
.title {
  font-size: 28px;
  line-height: 40px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);

}
.subTitle {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 32px;
}
.loginLink,
.loginLink:hover {
  color: #6F40EE;
  font-weight: bold;
}



@media (max-width: 960px) {
  .title {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
  }
  .subTitle {
    margin-top: 6px;
    margin-bottom: 25px;
    text-align: center;
  }
}

