
.leftSide {
  height: 100%;
  padding-top: 224px;
  position: relative;
  background: url(~@images/login/left_side_bg.webp);
  background-size: cover;
}

.logoContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  .logoText {
    color: hsla(0,0%,100%,.8);
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 24px;
  }
}
.downloadContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  width: auto;
  height: 64px;
  left: 30px;
  bottom: 25px;
}

.downloadText {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 20px;
  margin-left: 12px;
}
.downloadSmallText {
  color: rgba(255, 255, 255, 0.4);
  font-size: 12px;
  line-height: 17px;
}

@media (max-width: 960px) {
  .leftSide {
    display: none;
  }
}